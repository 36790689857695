<template>
    <div class="project-container">
        <div class="project-box">
            <project-info :id="id" :tabs="tabs" :project="project" :app_lists="app_lists">
                <template v-if="role === 4" v-slot:extender>
                    <task-commit :ids="ids"></task-commit>
                </template>
                <template v-slot:extend>
                    <ProjectSubmissionModule ref="getProjectSubmitList"></ProjectSubmissionModule>
                </template>
            </project-info>
        </div>
<!--        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>项目库</el-breadcrumb-item>
            <el-breadcrumb-item>
                <span class="color-blue" @click="goToProjectList">项目列表</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>项目详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="practical-details">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClickItem">
                <el-tab-pane label="项目详情" name="first">
                    <div class="project-box">
                        <el-scrollbar style="height: 100%" >

                        </el-scrollbar>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="项目提交" name="second">
                    <ProjectSubmissionModule ref="getProjectSubmitList"></ProjectSubmissionModule>
                </el-tab-pane>
            </el-tabs>
        </div>-->
    </div>
</template>

<script>
    import TaskCommit from "@/components/material/TaskCommit";
    import ProjectDetailModule from 'components/qualityCourse/ProjectDetailModule.vue'
    import ProjectSubmissionModule from 'components/qualityCourse/ProjectSubmissionModule.vue'
    import ProjectInfo from "@/components/material/ProjectInfo";
    export default {
        name: "ProjectDetail",
        data() {
            return {
                activeName: 'first',
                id: this.$route.query.p_id ? Number(this.$route.query.p_id) : 0,
                ids: {
                  project_id: this.$route.query.p_id ? Number(this.$route.query.p_id) : 0,
                  sx_id: 0
                },
                app_lists: [],
                project: {
                    project_title: '',
                    project_ability: [],
                    project_attrs: [],
                    project_evaluate: [],
                    project_knowledge_link: [],
                    project_sucai: [],
                    project_task: [],
                    project_duration: 0,
                    project_difficulty: 0
                },
                tabs: ['项目详情', '项目提交'],
                role: localStorage.getItem('role') ? Number(localStorage.getItem('role')) : 3,
                //项目详情数据
                // projectDetailData: {},
            }
        },
        created() {
            this.projectInfo();
        },
        components: {
            ProjectDetailModule,
            ProjectSubmissionModule,
            ProjectInfo,
            TaskCommit
        },
        methods: {
            //点击tab
            handleClickItem() {
                this.$refs.getProjectSubmitList.getStudentProject();
            },
            //返回项目列表页
            goToProjectList() {
                this.$router.push({
                    path: '/qualityCourse/Project'
                })
            },
            projectInfo() {
                this.$shttp.axiosGetBy(this.$api.getProjectDetail, { id: this.id }, (res) => {
                    if (res.code === 200) {
                        this.project = Object.assign({}, res.data);
                        this.app_lists = [].concat(res.data.app_lists);
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .project-container {
        display: flex;
        flex-direction: column;
        padding: 20px 70px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        .el-breadcrumb {
            padding: 0 0 20px 0;
             ::v-deep .el-breadcrumb__inner {
                color: #999;
                &:last-child {
                    color: #333;
                }
            }
            ::v-deep .el-breadcrumb__item {
                &:last-child ::v-deep .el-breadcrumb__inner {
                    color: #333;
                }
            }
        }
        .practical-details{
            height: calc(100% - 55px);
            display: flex;
            flex-direction: column;
            flex: 1;
            background-color: #fff;
            ::v-deep .el-tabs {
                height: 100%;
                display: flex;
                flex-direction: column;
                .el-tabs__content {
                    flex: 1;
                    height: calc(100% - 80px);
                    .el-tab-pane {
                        height: 100%;
                    }
                }
            }
            ::v-deep.el-tabs__item.is-active{
                color: #fff;
                background: #409EFF;
            }
            ::v-deep.el-tabs__active-bar{
                background-color: transparent;
            }
            ::v-deep.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            ::v-deep.el-tabs--top .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
            ::v-deep.el-tabs--card>.el-tabs__header{
                padding: 20px;
            }
            ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
                border: 1px solid #409eff;
                height: 40px;
                border-radius: 4px;
            }
            ::v-deep.el-tabs__header{
                margin: 0;
            }
        }
    }
    .project-box {
        width: 1200px;
        margin: 0 auto;
    }
</style>