<template>
    <el-scrollbar class="subject-content">
        <div class="head-box">
            <div class="head-content">
                <div class="img-box">
                    <img :src="projectDetailData.project_cover" alt="">
                </div>
                <div class="right-content">
                    <div class="row1">
                        <div class="left-box">
                            <div class="title-name">项目标题:</div>
                            <div class="titile-detail">{{projectDetailData.project_title}}</div>
                        </div>
                        <div class="right-box">
                            <div class="time-name">发布时间:</div>
                            <div class="time-detail">{{projectDetailData.create_time}}</div>
                        </div>
                    </div>
                    <div class="row2">
                        <div class="left-box">
                            <div class="title-name">项目简介:</div>
                            <div class="titile-detail">{{projectDetailData.project_content}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-content">
            <div class="card">
                <div class="first-show">
                    <div class='img-content'>
                        <img src="../../assets/images/project/project_target.png" alt="">
                    </div>
                    <div class='show-title'>项目目标</div>
                </div>
                <div class="card-content">
                    <div class="content-title">项目目标</div>
                    <div class="content-box">{{projectDetailData.project_target}}</div>
                </div>
            </div>
            <div class="card" style="margin-left: 30px;">
                <div class="first-show">
                    <div class='img-content'>
                        <img src="../../assets/images/project/project_describe.png" alt="">
                    </div>
                    <div class='show-title'>项目描述</div>
                </div>
                <div class="card-content">
                    <div class="content-title">项目描述</div>
                    <div class="content-box">{{projectDetailData.project_describe}}</div>
                </div>
            </div>
            <div class="card" style="margin-left: 30px;">
                <div class="first-show">
                    <div class='img-content'>
                        <img src="../../assets/images/project/project_analysis.png" alt="">
                    </div>
                    <div class='show-title'>项目分析</div>
                </div>
                <div class="card-content">
                    <div class="content-title">项目分析</div>
                    <div class="content-box">{{projectDetailData.project_analysis}}</div>
                </div>
            </div>
            <div class="card" style="margin-left: 30px;">
                <div class="first-show">
                    <div class='img-content'>
                        <img src="../../assets/images/project/project_preper.png" alt="">
                    </div>
                    <div class='show-title'>项目准备</div>
                </div>
                <div class="card-content">
                    <div class="content-title">项目准备</div>
                    <div class="content-box">{{projectDetailData.project_prepare}}</div>
                </div>
            </div>
        </div>
        <div class="task-box">
            <div class="task-title">项目任务</div>
            <div class="task-content">
                <el-carousel indicator-position="outside" height="622px" :autoplay=false>
                    <el-carousel-item v-for="(item,index) in taskList" :key="index">
                        <div class="task-main">
                            <div class="left-task">
                                <div class="task-title" v-if="item.text.indexOf('<')!=-1"
                                     v-html="item.text"></div>
                                <div class="task-title" v-else>{{item.text}}</div>
                            </div>
                            <div class="right-task">
                                <div class="right-img">
                                    <img :src="item.image" alt="">
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="step-box">
            <div class="step-title">项目步骤</div>
            <div class="table-box">
                <el-table class="elTable" :data="projectDetailData.project_step_score"
                          :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}">
                    <el-table-column class="el-table-column" prop="index" label="序号" align="left"
                                     width="200">
                    </el-table-column>
                    <el-table-column prop="content" label="内容" align="left"></el-table-column>
                    <el-table-column prop="score" label="分值" align="right" width="200"></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="ability-box">
            <div class="ability-title">能力扩展</div>
            <div class="ability-content">
                <div class="ability-content-box" v-if="projectDetailData.project_ability">
                    {{projectDetailData.project_ability}}
                </div>
                <div class="ability-content-box" v-else>暂无</div>
            </div>
        </div>
        <div class="knowledge-content">
            <div class="knowledge-title">知识链接</div>
            <div class="show-box">
                <div class="knowledge-list-box">
                    <div class="knowledge-list-content"
                         v-for="(item,index) in projectDetailData.project_knowledge_link" :key="index">
                        <div style="z-index: 1;cursor: pointer" @click="handleClick(item)">
                            <div class="circle-box" v-if="item.type == 1" style="background: #61BCEB">
                                <i class="iconfont">&#xe733;</i>
                            </div>
                            <div class="circle-box" v-if="item.type == 2" style="background: #3DCAC8">
                                <i class="iconfont">&#xeb62;</i>
                            </div>
                            <div class="circle-box" v-if="item.type == 3" style="background: #F0BC49">
                                <i class="iconfont">&#xeb61;</i>
                            </div>
                            <div class="circle-box" v-if="item.type == 4" style="background: #EC75A4">
                                <i class="iconfont">&#xeb63;</i>
                            </div>
                            <div class="circle-box" v-if="item.type == 5" style="background: #79ACDC">
                                <i class="iconfont">&#xe625;</i>
                            </div>
                            <div class="circle-box" v-if="item.type == 6" style="background: #ffffff">
                                <i class="iconfont">&#xe782;</i>
                            </div>
                            <div class="circle-box" v-if="item.type == 7" style="background: #5AB9E9">
                                <i class="iconfont">&#xe715;</i>
                            </div>
                        </div>
                        <div class="circle-bottom">{{item.name}}</div>
                    </div>
                    <div v-if="projectDetailData.project_knowledge_link === null" class="ability-content-box">暂无</div>
                </div>
            </div>
        </div>
        <div class="sucai-box">
            <div class="sucai-title">素材下载</div>
            <div class="sucai-content">
                <div v-if="projectDetailData.project_sucai.length === 0" class="ability-content-box">暂无</div>
                <div class="sucai-content-box" v-for="(item,index) in projectDetailData.project_sucai" :key="index">
                    <i class="iconfont">&#xe625;</i>
                    <span>{{item.file_name}}</span>
                    <span style="margin-left: 12px;" @click="downloadSucai(item.file_download)">
                        <i class="iconfont" style="color: #409EFF">&#xe725;</i>
                    </span>
                </div>
            </div>
        </div>
        <div class="practical-submit" v-if="role === 4">
            <div>
                <h1 style="font-size: 14px">项目提交</h1>
                <div id="editor-practical"></div>
                <div class="submit">
                    <el-button type="primary" @click="onSubmitProject()">提交</el-button>
                    <el-button @click="goBackList">返回</el-button>
                </div>
            </div>
        </div>

        <!--查看应用详情弹窗-->
        <el-dialog title="欢迎使用yikeSoft" :visible.sync="dialogAppDetail" width="800px" center class="app-detail-dialog"
                   :close-on-click-modal="false">
            <div class="app-detail-content">
                <h1 class="title">欢迎使用yikeSoft</h1>
                <div class="content">
                    <span class="h2">{{appInfo.appTitle}}</span>
                    <span class="soft-introduction">软件功能介绍：</span>
                    <div class="soft-content">{{appInfo.appContent}}</div>
                </div>
            </div>
            <div slot="footer" class="info-dialog-footer">
                <a :href="appInfo.home_link" target="_blank" class="home">首页登录</a>
                <a :href="appInfo.shop_link" target="_blank" class="shop">商家登录</a>
                <a :href="appInfo.teacher_link" target="_blank" class="shop">教师登录</a>
            </div>
        </el-dialog>
    </el-scrollbar>
</template>

<script>
    import WE from "../../utils/wEditor";
    export default {
        name: "ProjectDetailModule",
        props: ['projectId'],
        data() {
            return {
                role: Number(localStorage.getItem('role')),
                //项目详情数据
                projectDetailData: {
                    project_sucai: [],
                    project_knowledge_link: [],
                    project_task: []
                },
                //任务列表
                taskList: [],
                createTime: "",
                project_id: "",
                //应用信息
                appInfo: {
                    appTitle: '',
                    appContent: '',
                    home_link: '',
                    shop_link: '',
                    teacher_link: '',
                },
                //是否显示查看应用详情弹窗
                dialogAppDetail: false,
                editorText: null,
            }
        },
        mounted() {
            this.getProjectDetails();
            this.editorText = new WE('#editor-practical', this.$api.uploadKindeditor);
        },
        methods: {
            //获取项目详情
            getProjectDetails() {
                this.$shttp.axiosGetBy(this.$api.getProjectDetail, {id: Number(this.$route.query.p_id)}, res => {
                    if (res.code === 200) {
                        this.projectDetailData = res.data;
                        // this.timestampToTime(res.data.create_time);
                        if (res.data.project_task.length > 0) {
                            this.getProjectTaskList(res.data.project_task);
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取任务列表
            getProjectTaskList(val) {
                for (let i = 0; i < val.length; i++) {
                    this.taskList.push(val[i].project_task);
                    for (let j = 0; j < val[i].project_step.length; j++) {
                        this.taskList.push(val[i].project_step[j])
                    }
                }
            },
            timestampToTime(timestamp) {
                var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var D = date.getDate() + ' ';
                this.createTime = Y + M + D;
            },
            downloadSucai(url) {
                window.open(url);
            },
            handleClick(item) {
                if (item.type == 6) {
                    this.viewAppDetail(item);
                } else {
                    window.open(item.url, "_blank")
                }
            },
            //查看应用详情
            viewAppDetail(item) {
                this.dialogAppDetail = true;
                this.appInfo.appTitle = item.name;
                this.appInfo.appContent = item.intro;
                this.appInfo.home_link = item.home_link;
                this.appInfo.shop_link = item.shop_link;
                this.appInfo.teacher_link = item.teacher_link;
            },
            //提交
            onSubmitProject() {
                let formData = new FormData();
                formData.append('project_id', this.projectDetailData.id);
                formData.append('content', this.editorText.getWEHtml());
                this.$shttp.axiosPost(this.$api.drill_putDrillData, formData, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //返回列表页
            goBackList() {
                this.$router.push({
                    path: '/qualityCourse/Project'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-scrollbar__view {
        padding-right: 20px;
        padding-left: 20px;
    }

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    ::v-deep .el-carousel__indicators--outside {
        display: none;
    }

    .subject-content {
        flex: 1;
        height: calc(100% - 63px);

        .head-box {
            .title-box {
                display: flex;
            }

            .head-content {
                padding-top: 60px;
                display: flex;

                .img-box {
                    width: 200px;
                    height: 200px;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }

                .right-content {
                    margin-left: 40px;
                    overflow: hidden;
                    height: 200px;
                    width: calc(100% - 240px);

                    .row1 {
                        display: flex;
                        justify-content: space-between;

                        .left-box {
                            display: flex;

                            .title-name {
                                font-weight: bold;
                            }

                            .titile-detail {
                                margin-left: 10px;
                            }
                        }

                        .right-box {
                            display: flex;

                            .time-detail {
                                margin-left: 10px;
                            }

                            .titile-detail {
                                margin-left: 10px;
                            }
                        }
                    }

                    .row2 {
                        display: flex;
                        margin-top: 40px;

                        .left-box {
                            display: flex;

                            .title-name {
                                font-weight: bold;
                                min-width: 65px;
                            }

                            .titile-detail {
                                margin-left: 10px;
                                word-break: break-all;
                                line-height: 1.5;
                                max-height: 120px;
                            }
                        }
                    }

                }

            }
        }

        .box-content {
            display: flex;
            margin-top: 100px;

            .card {
                width: calc(25% - 20px);
                min-width: 250px;
                height: 518px;
                background: #FFFFFF;
                box-shadow: 0px 1px 5px 0px rgba(32, 32, 32, 0.14);
                border-radius: 10px;
                position: relative;

                .first-show {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .show-title {
                        font-weight: bold;
                        margin-top: 85px;
                    }
                }

                .card-content {
                    width: 80%;
                    position: absolute;
                    top: 100px;
                    left: 0;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    padding: 0 40px;

                    .content-box {
                        margin-top: 37px;
                        text-align: left;
                        word-break: break-all;
                        line-height: 1.5;
                        max-height: 300px;
                        overflow: hidden;
                    }
                }
            }

            .card:before {
                content: "";
                width: 0;
                height: 100%;
                background: #fff;
                position: absolute;
                top: 0;
                left: 50%;
                opacity: 0;
            }

            .card:hover:before {
                width: 100%;
                left: 0;
                opacity: 0.5;
            }

            .card:hover {
                box-shadow: 0px 5px 17px 1px rgba(32, 32, 32, 0.14);
                border-radius: 10px;
            }

            .card:hover .card-content {
                opacity: 1;
            }

            .card:hover .first-show {
                opacity: 0;
            }
        }

        .task-box {
            margin-top: 60px;

            .task-content {
                width: 100%;
                height: 622px;
                border: 1px solid #DDDDDD;
                border-radius: 10px;
                margin-top: 20px;

                .task-main {
                    display: flex;
                    height: 100%;

                    .left-task {
                        width: 50%;
                        padding: 76px 68px 40px 38px;
                        word-break: break-all;
                        line-height: 1.5;
                        overflow: hidden;
                        height: 80%;
                    }

                    .right-task {
                        width: 50%;
                        height: 100%;

                        .right-img {
                            padding: 40px 40px 40px 0;
                            width: 95%;
                            height: 88%;

                            img {
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .step-box {
            margin-top: 60px;

            .step-title {
                font-weight: bold;
            }

            .table-box {
                border: 1px solid #DDDDDD;
                margin-top: 20px;
            }
        }

        .ability-box {
            margin-top: 60px;

            .ability-title {
                font-weight: bold;
            }

            .ability-content {
                margin-top: 20px;
                min-height: 100px;
                background: #F5F7FA;
                border-radius: 10px;

                .ability-content-box {
                    padding: 20px;
                    word-break: break-all;
                    line-height: 1.5;
                }
            }
        }

        .knowledge-content {
            display: flex;
            flex-direction: column;
            margin-top: 60px;

            .knowledge-title {
                font-weight: bold;
            }

            .btn {
                margin-top: 20px;
                width: 100px;
                height: 30px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                text-align: center;
                line-height: 2;
                cursor: pointer;
            }

            .show-box {
                min-height: 100px;
                background: #F5F7FA;
                border-radius: 10px;
                margin-top: 10px;
                display: flex;

                .knowledge-list-box {
                    width: 100%;
                    padding-top: 10px;
                    display: flex;
                    flex-wrap: wrap;

                    .knowledge-list-content {
                        position: relative;
                        width: calc(10% - 30px);
                        min-width: 100px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0 20px;

                        .circle-box {
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #ffffff;

                            ::v-deep .iconfont {
                                font-size: 26px;
                            }
                        }

                        .circle-bottom {
                            height: 30px;
                            overflow: hidden;
                            margin-top: 10px;
                            max-width: 100%;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .del-btn {
                            width: 18px;
                            height: 18px;
                            background: #FF0000;
                            color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            position: absolute;
                            right: 0;
                            top: 20px;
                            opacity: 0;
                            cursor: pointer;
                            z-index: 99;
                        }
                    }

                    .knowledge-list-content:before {
                        content: "";
                        width: 0;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        opacity: 0;
                    }


                    .knowledge-list-content:hover:before {
                        width: 100%;
                        left: 0;
                        opacity: 0.5;
                    }

                    .knowledge-list-content:hover .del-btn {
                        opacity: 1;
                    }
                }
            }
        }

        .sucai-box {
            margin-top: 60px;

            .btn {
                margin-top: 20px;
                width: 100px;
                height: 30px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                text-align: center;
                line-height: 2;
                cursor: pointer;
            }

            .sucai-title {
                font-weight: bold;
            }

            .sucai-content {
                margin-top: 20px;
                min-height: 70px;
                background: #F5F7FA;
                border-radius: 10px;
                display: flex;

                .sucai-content-box {
                    padding: 20px;
                    word-break: break-all;
                    line-height: 1.5;
                }
            }
        }
    }






    .main-box {
        height: calc(100% - 120px);
        width: 93%;
        margin: 20px auto;
        background: #ffffff;

        .main-content {
            padding: 20px 100px 0 100px;
            background: #ffffff;
            height: 100%;


        }

        .app-detail-dialog {
            ::v-deep .el-dialog {
                ::v-deep .el-dialog__header {
                    border-bottom: 1px solid #e6e7ea;
                }

                .title {
                    text-align: center;
                    margin: 0;
                }

                .content {
                    display: flex;
                    flex-direction: column;

                    .h2 {
                        font-size: 18px;
                        font-weight: 500;
                        text-align: center;
                        margin: 10px 0;
                    }

                    .soft-introduction {
                        text-align: center;
                    }

                    .soft-content {
                        padding: 10px 50px;
                        min-height: 200px;
                    }
                }

                ::v-deep .el-dialog__footer {
                    border-top: 1px solid #e6e7ea;
                    padding: 20px;

                    a {
                        padding: 7px 20px;
                        font-size: 14px;
                        border-radius: 4px;
                        margin-right: 20px;
                    }

                    a.home {
                        border: 1px solid #DCDFE6;
                        color: #606266;

                        &:hover {
                            color: #409EFF;
                            border-color: #c6e2ff;
                            background-color: #ecf5ff;
                        }
                    }

                    a.shop {
                        color: #FFF;
                        background-color: #409EFF;
                        border-color: #409EFF;

                        &:hover {
                            background: #66b1ff;
                            border-color: #66b1ff;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
    .app-detail-dialog {
        ::v-deep .el-dialog {
            ::v-deep .el-dialog__header {
                border-bottom: 1px solid #e6e7ea;
            }

            .title {
                text-align: center;
                margin: 0;
            }

            .content {
                display: flex;
                flex-direction: column;

                .h2 {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    margin: 10px 0;
                }

                .soft-introduction {
                    text-align: center;
                }

                .soft-content {
                    padding: 10px 50px;
                    min-height: 200px;
                }
            }

            ::v-deep .el-dialog__footer {
                border-top: 1px solid #e6e7ea;
                padding: 20px;

                a {
                    padding: 7px 20px;
                    font-size: 14px;
                    border-radius: 4px;
                    margin-right: 20px;
                }

                a.home {
                    border: 1px solid #DCDFE6;
                    color: #606266;

                    &:hover {
                        color: #409EFF;
                        border-color: #c6e2ff;
                        background-color: #ecf5ff;
                    }
                }

                a.shop {
                    color: #FFF;
                    background-color: #409EFF;
                    border-color: #409EFF;

                    &:hover {
                        background: #66b1ff;
                        border-color: #66b1ff;
                        color: #FFF;
                    }
                }
            }
        }
    }
    .practical-submit {
        /*padding: 0 20px;*/
        .submit {
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            -webkit-justify-content: center;
            padding: 20px 0;
        }
    }
    .ability-content-box {
        padding: 20px;
        word-break: break-all;
        line-height: 1.5;
    }
</style>